import accountService from 'common/services/file-service/file.service';
import { UploadFileTypes } from 'common/services/file-service/file.service.types';
import React, { useState } from 'react';
import { Image } from 'antd';
import image from 'assets/icons/preveiw-image.png';

interface IEIQImageProps {
  url: string;
  fileType: UploadFileTypes;
  alt?: string;
}

const EIQPreviewImage = ({ url, fileType, alt }: IEIQImageProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  const getFile = async () => {
    const response = await accountService.getFile(url, fileType);
    const imgUrl = URL.createObjectURL(response);
    setImageUrl(imgUrl);
  };

  return (
    <Image
      width={20}
      src={image}
      alt={alt}
      loading="lazy"
      preview={{
        visible: previewVisible,
        src: imageUrl ? imageUrl : image,
        onVisibleChange: (value: boolean, prevValue: boolean) => {
          if (value) {
            setPreviewVisible(true);
            getFile();
          } else {
            setPreviewVisible(false);
          }
        },
      }}
    />
  );
};

export default EIQPreviewImage;
