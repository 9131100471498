import { makeAutoObservable } from 'mobx';
import { configure, DataCaptureContext, Camera, DataCaptureView, FrameSourceState, Logger } from '@scandit/web-datacapture-core';
import {
  DriverLicense,
  IdCapture,
  idCaptureLoader,
  IdCaptureOverlay,
  IdCaptureSettings,
  IdCard,
  IdImageType,
  Region,
  SingleSideScanner,
} from '@scandit/web-datacapture-id';

export enum IdDocumentRecognitionSDKStatus {
  Uninitialized = 'uninitialized',
  Initializing = 'initializing',
  Ready = 'ready',
  Error = 'error',
}

class IdDocumentRecognitionStore {
  dataCaptureContext: DataCaptureContext | null;
  camera: Camera | null;
  idCapture: IdCapture | null;
  dataCaptureView: DataCaptureView | null;
  status: IdDocumentRecognitionSDKStatus;

  private _isScanning: boolean;

  constructor() {
    makeAutoObservable(this);
    this.dataCaptureContext = null;
    this.camera = null;
    this.idCapture = null;
    this.dataCaptureView = null;
    this._isScanning = false;

    this.status = IdDocumentRecognitionSDKStatus.Uninitialized;
  }

  async init() {
    if (this.status !== IdDocumentRecognitionSDKStatus.Uninitialized) {
      return;
    }

    const licenseKey = process.env.REACT_APP_SCANDIT_KEY;
    const libraryLocation = process.env.REACT_APP_SCANDIT_SDK;

    if (!licenseKey || !libraryLocation) {
      return;
    }
    this.setStatus(IdDocumentRecognitionSDKStatus.Initializing);

    try {
      await configure({
        licenseKey: licenseKey,
        libraryLocation: libraryLocation,
        moduleLoaders: [idCaptureLoader({ enableVIZDocuments: true })],
        logLevel: Logger.Level.Warn,
      });

      this.dataCaptureContext = await DataCaptureContext.create();
      this.camera = Camera.default;
      await this.camera.applySettings(IdCapture.recommendedCameraSettings);
      await this.dataCaptureContext.setFrameSource(this.camera);

      const idCaptureSettings = new IdCaptureSettings();

      idCaptureSettings.acceptedDocuments.push(new DriverLicense(Region.Any));
      idCaptureSettings.acceptedDocuments.push(new IdCard(Region.Any));
      idCaptureSettings.setShouldPassImageTypeToResult(IdImageType.CroppedDocument, true);

      idCaptureSettings.scannerType = new SingleSideScanner(true, true, true);

      this.idCapture = await IdCapture.forContext(this.dataCaptureContext, idCaptureSettings);
      this.dataCaptureView = await DataCaptureView.forContext(this.dataCaptureContext);

      this.setStatus(IdDocumentRecognitionSDKStatus.Ready);
    } catch (error) {
      console.log(error);
      this.setStatus(IdDocumentRecognitionSDKStatus.Error);
    }
  }

  async enableScanning(enabled: boolean) {
    if (this.status !== IdDocumentRecognitionSDKStatus.Ready || this._isScanning === enabled) {
      return;
    }

    this._isScanning = enabled;
    await this.dataCaptureContext?.frameSource?.switchToDesiredState(enabled ? FrameSourceState.On : FrameSourceState.Off);
    this.idCapture?.setEnabled(enabled);
  }

  detachElement() {
    this.dataCaptureView?.detachFromElement();
  }

  async connectToElement(htmlElement: HTMLElement) {
    if (!this.dataCaptureView || !this.idCapture) {
      return;
    }

    this.dataCaptureView.connectToElement(htmlElement);
    await IdCaptureOverlay.withIdCaptureForView(this.idCapture, this.dataCaptureView);
  }

  async cleanup() {
    this.detachElement();
    await this.idCapture?.setEnabled(false);
    await this.dataCaptureContext?.frameSource?.switchToDesiredState(FrameSourceState.Off);
    await this.dataCaptureContext?.removeAllModes();
    await this.dataCaptureContext?.dispose();

    this.setStatus(IdDocumentRecognitionSDKStatus.Uninitialized);

    this._isScanning = false;
  }

  setStatus(status: IdDocumentRecognitionSDKStatus) {
    this.status = status;
  }
}

const idDocumentRecognitionContext = new IdDocumentRecognitionStore();
export default idDocumentRecognitionContext;
