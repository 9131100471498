export enum FeatureName {
  FastAccess = 'FastAccess',
  FastAccessRestrictions = 'FastAccessRestrictions',
  FastAccessAlerts = 'FastAccessAlerts',
  SmsNotifications = 'SmsNotifications',
  SmsMassAlerts = 'SmsMassAlerts',
  CA4K = 'CA4K',
  CA3000 = 'CA3000',
  EIQIntelliTicket = 'EIQIntelliTicket',
  Insight = 'Insight',
  Connect = 'Connect',
  CA3000CardClasses = 'CA3000CardClasses',
  CA3000Expirations = 'CA3000Expirations',
  NorthStar = 'NorthStar',
  ConnectEditPin = 'ConnectEditPin',
  CA3KLogsImport = 'CA3KLogsImport',
  CreateResidentAnonymously = 'CreateResidentAnonymously',
  EIQViolation = 'EIQViolation',
  MassAlerts = 'MassAlerts',
  IncidentReporter = 'IncidentReporter',
  LPR = 'LPR',
  PlateGuard = 'PlateGuard',
  GuestLimitations = 'GuestLimitations',
  BasIP = 'BasIP',
  GuestLimitationsForAdmins = 'GuestLimitationsForAdmins',
  DriveId = 'DriveId',
}

export const featureNameMap: Record<FeatureName, string> = {
  [FeatureName.FastAccess]: 'EIQ-Passport',
  [FeatureName.FastAccessRestrictions]: 'EIQ-Passport Restrictions',
  [FeatureName.FastAccessAlerts]: 'EIQ-Passport Alerts',
  [FeatureName.SmsNotifications]: 'SMS Notifications',
  [FeatureName.SmsMassAlerts]: 'SMS for the EIQ-Alert',
  [FeatureName.CA4K]: 'CA4K',
  [FeatureName.CA3000]: 'CA3000',
  [FeatureName.EIQIntelliTicket]: 'EIQ-IntelliTicket',
  [FeatureName.Insight]: 'EIQ-Insight',
  [FeatureName.Connect]: 'EIQ-Connect',
  [FeatureName.CA3000CardClasses]: 'CA3000 Card Classes',
  [FeatureName.CA3000Expirations]: 'CA3000 Expirations',
  [FeatureName.NorthStar]: 'EIQ-NorthSync',
  [FeatureName.ConnectEditPin]: 'Edit Pin',
  [FeatureName.CA3KLogsImport]: 'CA3000 Event Logs',
  [FeatureName.CreateResidentAnonymously]: 'Anonymous Resident Creation',
  [FeatureName.EIQViolation]: 'EIQ-Violation',
  [FeatureName.MassAlerts]: 'EIQ-Alerts',
  [FeatureName.IncidentReporter]: 'EIQ-Reporter',
  [FeatureName.LPR]: 'License Plate Recognition',
  [FeatureName.PlateGuard]: 'PlateGuard',
  [FeatureName.GuestLimitations]: 'Guest Limitations',
  [FeatureName.BasIP]: 'BasIP',
  [FeatureName.GuestLimitationsForAdmins]: 'Restrict EIQ-Manage Option',
  [FeatureName.DriveId]: 'Drive Id',
};
