import { ITableHeightConfig } from './table.types';
import userPersonalizationStore from 'common/stores/user-personalization/user-personalization.store';

export const calculateTableHeight = (tableHeightConfig: ITableHeightConfig) => {
  const { tableDataLength, maxTableHeight, tableRowHeight, maxRowsInTable } = tableHeightConfig;
  return tableDataLength >= maxRowsInTable
    ? `${maxTableHeight}px`
    : `${maxTableHeight + (tableDataLength - maxRowsInTable) * tableRowHeight}px`;
};

export const baseModalTableHeightConfig: ITableHeightConfig = {
  tableDataLength: 0,
  maxTableHeight: 192,
  tableRowHeight: 32,
  maxRowsInTable: 5,
};

export const personalizationDefaultTaleSort = (tableName: string) => {
  const sort = userPersonalizationStore?.personalization?.tablePersonalizationData?.[tableName]?.tableSort;

  if (!sort) {
    return;
  }

  return { key: sort.name, order: sort.order };
};

export const filterColumnsByPersonalization = (columns: any[], tableName: string, filterColumns?: (columns: any[]) => any[]) => {
  let result = columns;

  if (filterColumns) {
    result = filterColumns(result);
  }

  if (!userPersonalizationStore?.personalization?.tablePersonalizationData?.[tableName]?.tableColumns) {
    return result;
  }

  result.forEach((c) => {
    const column = getColumn(tableName, c);
    if (column) {
      c.hidden = !column.isVisible;
      c.width = column.size ? column.size : c.width;
    }
  });

  result = result.sort(
    (a, b) =>
      getColumnOrder(
        getColumn(tableName, a),
        result.findIndex((i) => i.key === a.key),
      ) -
      getColumnOrder(
        getColumn(tableName, b),
        result.findIndex((i) => i.key === a.key),
      ),
  );
  return result;
};

const getColumnOrder = (column: any, index: number) => {
  return column ? column.order : index;
};

const getColumn = (tableName: string, column: any) =>
  userPersonalizationStore.personalization?.tablePersonalizationData[tableName]?.tableColumns.find((i) => i.name === column.key);

export const getTablePersonalizationColumnsData = (columns: any) =>
  columns.map((column: any, index: number) => ({ name: column.key, isVisible: !column.hidden, order: index, size: column.width }));

export const getTablePersonalizationSortData = (sortBy: any) => {
  return sortBy ? { name: sortBy.key, order: sortBy.order } : null;
};
