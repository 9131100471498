import React, { useEffect, useMemo, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import TableContextProvider, { useTableContext, useTableControls } from 'common/components/table/table-context';
import { eiqSecureGuestInitialColumns, guestColumns } from 'common/list-configs/guest-list.config';
import { Breadcrumb, Button, Checkbox, Col, Form, Input, Row, Select, Space, Switch } from 'antd';
import TableBase from 'common/components/table/table-base';
import eiqSecureStore from '../../stores/eiq-secure.store';
import { ReactComponent as AdmitIcon } from 'assets/icons/admit.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/collect-key.svg';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { ReactComponent as TurnbackIcon } from 'assets/icons/turnback.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { FormWrapper } from '../../../../common/components/form-wrapper/form-wrapper';
import './eiq-secure-gust-license.scss';
import guestService from '../../../../common/services/guest-service/guest.service';
import { notification } from '../../../../common/utils/notification';
import { formatAddressLabel } from '../../../../common/helpers/address.helper';
import { useModal } from '../../../../common/hooks/use-modal/use-modal.hook';
import TurnbackModal from '../turnback-modal/turnback-modal';
import ContactsModal from '../contacts-modal/contacts-modal';
import KeyActionModal from '../key-action-modal/key-action-modal-modal';
import configurationStore from 'common/stores/configuration/configuration.store';
import residentsService from '../../../../common/services/residents-service/residents.service';
import addressesKeyService from '../../../../common/services/addresses-key-service/addresses-key.service';
import InputPhone from '../../../../common/components/form-elements/input-phone/input-phone';
import { eiqSecureGuestLicenseValidation } from './eiq-secure-guest-license.validation';
import { toGateOptions } from 'features/eiq-secure/constans/common.eiq-secure';
import SelectState from 'common/components/form-elements/select-state/select-state';
import { TurnBack, Admit } from 'features/eiq-secure/constans/admit-actions.constants';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';
import { AdmitGuestEnum } from '../../constans/admit-types';
import Footer from '../../../../common/components/footer/footer';
import { setResidentListSearchFilter } from 'common/helpers/search-filter.helper';
import { useReactToPrint } from 'react-to-print';
import EiqSecureGuestLicensePrintInstruction from './eiq-secure-guest-license-print-instruction';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { useJsApiLoader } from '@react-google-maps/api';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';
import { IGuestRead } from 'common/services/residents-service/residents.service.types';
import { generatePdfFromHtml } from 'common/helpers/generate-pdf.helper';
import { EntryType } from 'common/enums/entry-type.enum';
import plateRecognitionContext from 'features/eiq-secure/stores/license-plate-recognition-context';
import { Image } from 'antd';
import { useDefaultAdmitValues } from 'features/eiq-secure/hooks/use-default-admit-values/use-default-admit-values.hook';
import { filterColumnsByPersonalization } from 'common/components/table/table-helpers';
import { EIQ_SECURE_GUEST_LIST_TABLE_NAME } from '../eiq-secure-guests-list/eiq-secure-guest-list';
import ScanditIdCapture from 'common/components/id-capture/scandit-id-capture';
import { IIdDocumentModel } from 'common/components/id-capture/scandit-id-capture.types';
import { base64ToFile } from 'common/helpers/getBase64.helper';
import accountService from 'common/services/file-service/file.service';
import { UploadFileTypes } from 'common/services/file-service/file.service.types';
import { IAddIdDocument } from 'common/services/guest-service/guest.service.types';
import EIQImage from 'common/components/image/eiq-image';
import featureStore from 'common/stores/features/feature.store';
import { CameraOutlined } from '@ant-design/icons';

interface IEiqSecureGuestLicenseProps {
  guestLicenseAdmit: boolean;
}

const LICENSE_PRINT_ID = 'license-print-id';

const entryTypeOptions = [
  {
    label: 'Driver',
    value: EntryType.Driver,
    isApproved: false,
  },
  {
    label: 'Pedestrian',
    value: EntryType.Pedestrian,
    isApproved: false,
  },
  {
    label: 'Passenger',
    value: EntryType.Passenger,
    isApproved: false,
  },
];

export const EiqSecureGuestLicense = observer(({ guestLicenseAdmit }: IEiqSecureGuestLicenseProps) => {
  const { isMobileOrTable } = useMedia();
  const tableContext = useTableContext();
  const [selectedAction, setSelectedAction] = useState<string>(Admit);
  const [turnbackReason, setTurnbackReason] = useState<string>();
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [keyOptions, setKeyOptions] = useState<{ value: string; label: string }[]>([]);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(true);
  const [selectedGate, setSelectedGate] = useState<string>();
  const [licensePlate, setLicensePale] = useState<string>();

  const [idDocument, setIdDocument] = useState<IIdDocumentModel | null>();

  const [form] = Form.useForm();
  const entryTypeValue = Form.useWatch('entryType', form);

  const isKeyPermissionAllowed = eiqSecureStore.selectedGuest?.isKeyPermissionAllowed;

  const { defaultGate, defaultLicenseState, defaultLicensePlate, image, camera } = useDefaultAdmitValues({
    currentGuest: eiqSecureStore.selectedGuest,
  });

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey });

  const gateOptions = useMemo(() => toGateOptions(configurationStore?.configuration?.gates || []), []);

  const defaultEntryType = useMemo(() => {
    const lprContext = plateRecognitionContext.data;

    if (lprContext) {
      return EntryType.Driver;
    }

    return entryTypeOptions?.find((i) => i.isApproved)?.value || entryTypeOptions?.[0]?.value;
  }, []);

  const data = useMemo(() => {
    return [eiqSecureStore.selectedGuest];
  }, [eiqSecureStore.selectedGuest]);

  let columns = eiqSecureGuestInitialColumns;

  if (!featureStore.isDriveIdEnabled) {
    columns = columns.filter((i) => i.key !== 'idDocument');
  }

  // Table config
  const tableControls = useTableControls({
    clientSide: true,
    data: data,
    initialColumns: filterColumnsByPersonalization(columns, EIQ_SECURE_GUEST_LIST_TABLE_NAME),
  });

  useEffect(() => {
    if (isSwitchChecked) {
      const filters = `address_id: ${eiqSecureStore.selectedAddressId}`;
      addressesKeyService.getKeys({ offset: 0, limit: 20 }, filters, '').then((result) => {
        const options = result?.items?.map((item) => ({
          value: item.keyNumber.toString(),
          label: `${item.keyNumber.toString()} (${item.name})`,
          disabled: item.status === 'out',
        }));
        setKeyOptions(options);
      });
    } else setKeyOptions([]);
  }, [isSwitchChecked]);

  useEffect(() => {
    if (guestLicenseAdmit) handleClickAdmit();
  }, [guestLicenseAdmit]);

  const updateGuestAndResidentDetails = () => {
    residentsService
      .getResidentDetails(eiqSecureStore.selectedResident?.id!)
      .then((data) => {
        for (const address of data.addresses) {
          const foundGuest = address.guests.find((guest) => guest.id === eiqSecureStore.selectedGuest?.id);
          if (foundGuest) {
            eiqSecureStore.setSelectedGuest(foundGuest);
            break;
          }
        }
        tableControls.updateSelectedRow(data);
        eiqSecureStore.setResidentDetails(data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const addIdDocument = async (residentId: number, addressId: number, guestId: number) => {
    if (!idDocument) {
      return;
    }

    const documentNameFileName = `${idDocument.firstName}_${idDocument.firstName}_${idDocument.type}.jpg`;
    const file = base64ToFile(idDocument.imageBase64, documentNameFileName);
    const uploadResultl = await accountService.uploadPhoto(file, UploadFileTypes.IdDocument);

    const requestData: IAddIdDocument = {
      type: idDocument.type,
      imageUrl: uploadResultl.absoluteUri,
      firstName: idDocument.firstName,
      lastName: idDocument.lastName,
      secondaryLastName: idDocument.secondaryLastName,
      gender: idDocument.gender,
      dateOfBirth: idDocument.dateOfBirth,
      nationality: idDocument.nationality,
      address: idDocument.address,
      issuingCountry: idDocument.issuingCountry,
      issuingCountryIso: idDocument.issuingCountryIso,
      number: idDocument.number,
      additionalNumber: idDocument.additionalNumber,
      expiredAt: idDocument.expiredAt,
      issuedAt: idDocument.issuedAt,
    };
    const result = await guestService.addIdDocument(residentId, addressId, guestId, requestData);

    const selectedGuest = eiqSecureStore.selectedGuest!;
    eiqSecureStore.setSelectedGuest({ ...selectedGuest, idDocument: result });
  };

  const onFinish = async (values: any) => {
    const { carLicensePlate, carState, entryType } = values;

    const residentId = eiqSecureStore?.residentDetails?.id;
    const selectedAddressId = eiqSecureStore?.selectedAddressId;
    const selectedGuestId = eiqSecureStore?.selectedGuest?.id;

    if (!residentId || !selectedAddressId || !selectedGuestId) {
      return;
    }

    await addIdDocument(residentId, selectedAddressId, selectedGuestId);

    const admitGuestBody = {
      idDocumentUrl: eiqSecureStore.selectedGuest?.idDocument?.imageUrl,
      entryType,
      carLicensePlate,
      carState,
      image: image,
      camera: camera,
      driverName: entryType === EntryType.Passenger ? `${values.driverFirstName ?? ''} ${values.driverLastName ?? ''}`.trim() : null,
      driverCompanyName: entryType === EntryType.Passenger ? values.driverCompanyName : null,
      ...values,
    };

    if (selectedAction === Admit) {
      if (password) {
        admitGuestBody.key.password = password;
      }
      return guestService
        .admitGuest(residentId, selectedAddressId, selectedGuestId, admitGuestBody)
        .then(() => {
          notification.success({ message: 'Guest has been admitted' });
          updateGuestAndResidentDetails();
          tableContext.setSelectedRow(null);
          eiqSecureStore.setAdmitFlow(null);
          eiqSecureStore.setIsAdmitFlowProcessed();
          setResidentListSearchFilter('');
          plateRecognitionContext.clear();
        })
        .catch((error) => {
          if (error.response.status === 403) {
            notification.error({ message: 'Password is invalid' });
          }
        });
    } else {
      admitGuestBody.reason = turnbackReason;
      return guestService.turnbackGuest(residentId, selectedAddressId, selectedGuestId, admitGuestBody).then(() => {
        notification.success({ message: 'The guest has been turned back' });
        updateGuestAndResidentDetails();
        tableContext.setSelectedRow(null);
        eiqSecureStore.setAdmitFlow(null);
        eiqSecureStore.setIsAdmitFlowProcessed();
        plateRecognitionContext.clear();
        setResidentListSearchFilter('');
      });
    }
  };

  const handleOnValuesChange = (_: any, values: any) => {
    if (!values.gate) {
      setIsButtonsDisabled(true);
      return;
    }

    if (values.entryType === EntryType.Driver && (!values.carLicensePlate || !values.carState)) {
      setIsButtonsDisabled(true);
      return;
    }

    if (isSwitchChecked && (!values.key.guestPhoneNumber || !values.key.keyNumbers?.length)) {
      setIsButtonsDisabled(true);
      return;
    }
    setSelectedGate(values.gate);
    setLicensePale(values.carLicensePlate);
    setIsButtonsDisabled(false);
  };

  useEffect(() => {
    if (!form) {
      setIsButtonsDisabled(true);
      return;
    }
    const values = form.getFieldsValue();
    handleOnValuesChange(null, values);
  }, [form, isSwitchChecked, entryTypeValue]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  const handleClickAdmit = () => {
    setSelectedAction(Admit);
    form.submit();
    if (configurationStore.configuration?.default.isPrintPasses) {
      handlePrint();
    }
  };

  const contactsModal = useModal();

  const turnbackModal = useModal({
    onClose: (isSuccessSaved, turnbackReason) => {
      if (isSuccessSaved) {
        setSelectedAction(TurnBack);
        setTurnbackReason(turnbackReason);
        form.submit();
      }
    },
  });

  const provideKeyModal = useModal({
    onClose: (isSuccessSaved, password) => {
      if (isSuccessSaved) {
        setPassword(password);
        setIsSwitchChecked(true);
      } else {
        setIsSwitchChecked(false);
        setPassword('');
      }
    },
  });

  const collectKeyModal = useModal({
    onClose: (isSuccessSaved) => {
      if (isSuccessSaved) updateGuestAndResidentDetails();
    },
  });

  const printRef = useRef<any>();
  const [steps, setSteps] = useState<any>();
  const promiseResolveRef = useRef<any>(null);

  useEffect(() => {
    if (promiseResolveRef.current) {
      promiseResolveRef?.current();
    }
  }, [steps]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    print: configurationStore.configuration?.default.isPrintPasses
      ? async (printIframe: HTMLIFrameElement) => {
          const document = printIframe.contentDocument;
          const licenseHtml = document?.getElementById(LICENSE_PRINT_ID);
          if (!licenseHtml) {
            return;
          }

          await generatePdfFromHtml(licenseHtml, 'license.pdf');
        }
      : undefined,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        if (isLoaded) {
          const gate = configurationStore.configuration?.gates.find((i) => i.name === selectedGate);

          if (!gate?.latitude || !gate.longitude) {
            resolve(null);
          } else {
            const directionsService = new google.maps.DirectionsService();
            const origin = new google.maps.LatLng(gate.latitude, gate.longitude);
            const { city, state } = configurationStore.communitySettings!;
            const address = tableContext.getSelectedRow()?.address;

            const request: google.maps.DirectionsRequest = {
              destination: `${state}, ${city}, ${formatAddressLabel(address)}`,
              origin: origin,
              travelMode: google.maps.TravelMode.DRIVING,
            };

            directionsService.route(request, (result, status) => {
              if (status === 'OK') {
                promiseResolveRef.current = resolve;
                setSteps(result);
              } else {
                console.error(`Directions request failed due to ${status}`);
                resolve(null);
              }
            });
          }
        } else {
          resolve(null);
        }
      });
    },
  });

  const admitBtn = (
    <Button type="primary" className="primary-btn mobile-btn" icon={<AdmitIcon />} onClick={handleClickAdmit} disabled={isButtonsDisabled}>
      Approve
    </Button>
  );

  const admitActions = (
    <Space size={8} className="buttons-font-16">
      <Button type="default" icon={<PrintIcon />} size={'middle'} onClick={handlePrint} className="mobile-btn" disabled={isButtonsDisabled}>
        Print directions
      </Button>
      <Button
        icon={<KeyIcon />}
        onClick={collectKeyModal.showModal}
        disabled={!eiqSecureStore.selectedGuest?.issuedKeys.length}
        className="mobile-btn">
        Collect key
      </Button>
      <Button className="important-btn mobile-btn" icon={<TurnbackIcon />} onClick={turnbackModal.showModal} disabled={isButtonsDisabled}>
        Turnback
      </Button>
      <Button className="secondary-btn mobile-btn" icon={<PhoneIcon />} onClick={contactsModal.showModal}>
        Dial
      </Button>
      {admitBtn}
    </Space>
  );

  const getGuestName = () => {
    const guest = tableControls.getSelectedRow() as IGuestRead;

    if (isServiceGuest(guest?.type)) {
      return guest?.companyName;
    } else {
      return `${guest?.firstName} ${guest?.lastName}`;
    }
  };

  const onEntryTypeChanged = async (newValue: EntryType) => {
    if (newValue === EntryType.Passenger) {
      form.setFieldValue('carLicensePlate', '');
      form.setFieldValue('carState', defaultLicenseState);
    }
    if (newValue === EntryType.Driver) {
      form.setFieldValue('carLicensePlate', defaultLicensePlate);
      form.setFieldValue('carState', defaultLicenseState);
    }
  };

  const onIdDocumentRecognized = async (idDocument: IIdDocumentModel) => {
    notification.destroy();
    setIdDocument(idDocument);
  };

  const onIdDocumentRecognizeFailed = (message: string) => {
    notification.error({ message: message });
  };

  const getIdDocumentSection = () => {
    const selectedGuest = eiqSecureStore.selectedGuest;

    if (selectedGuest?.idDocument) {
      return <EIQImage url={selectedGuest.idDocument.imageUrl} fileType={UploadFileTypes.IdDocument} alt="Id document img" />;
    }

    if (idDocument) {
      return <Image src={`data:image/jpeg;base64,${idDocument.imageBase64}`} loading="lazy" />;
    } else {
      return <ScanditIdCapture onRecognized={onIdDocumentRecognized} onFailed={onIdDocumentRecognizeFailed} />;
    }
  };

  const isInfoSectionEnabled = () => featureStore.isDriveIdEnabled || featureStore.isPlateScanEnabled;

  useEffect(() => {
    if (idDocument) {
      form.setFieldValue('firstName', idDocument.firstName);
      form.setFieldValue('lastName', idDocument.lastName);
    }
  }, [idDocument]);

  const onUpdateFromCameraClicked = () => {
    form.setFieldValue('firstName', '');
    form.setFieldValue('lastName', '');

    if (eiqSecureStore.selectedGuest) {
      eiqSecureStore.setSelectedGuest({ ...eiqSecureStore.selectedGuest, idDocument: null });
      setIdDocument(null);
    }
  };

  return (
    <div>
      <EiqSecureGuestLicensePrintInstruction
        id={LICENSE_PRINT_ID}
        ref={printRef}
        address={tableContext.getSelectedRow()?.address}
        gate={selectedGate}
        guestLicense={licensePlate}
        guestName={getGuestName()}
        rules={configurationStore.configuration?.default.communityRules}
        communityName={configurationStore.configuration?.default.communityName ?? ''}
        steps={steps}
        isMinimize={steps?.routes[0].legs[0].steps.length > 7}
        guest={tableControls.getSelectedRow()}
      />
      <TableContextProvider controls={tableControls}>
        <div className="gust-license">
          <Row justify="space-between">
            <Col>
              <div className="space-12">
                <div className="breadcrumb-wrapper">
                  <Button
                    icon={<BackIcon />}
                    onClick={() => {
                      eiqSecureStore.setAdmitFlow(isMobileOrTable ? AdmitGuestEnum.SELECT_GUEST : null);
                    }}>
                    Back
                  </Button>
                  <Breadcrumb
                    separator="–"
                    items={[
                      {
                        title: ' Guests',
                        onClick: () => {
                          eiqSecureStore.setAdmitFlow(isMobileOrTable ? AdmitGuestEnum.SELECT_GUEST : null);
                        },
                      },
                      {
                        title: 'License',
                      },
                    ]}
                  />
                </div>
                <span className="sub-title">{formatAddressLabel(eiqSecureStore.getSelectedAddress())}</span>
              </div>
            </Col>
            <Col>{!isMobileOrTable && admitActions}</Col>
          </Row>

          <TableBase selectFirstRowByDefault minHeight={'80px'} />

          <div className="container-row">
            <Row gutter={32}>
              {isInfoSectionEnabled() && (
                <Col lg={8} sm={24} className="instructions-wrapper">
                  {featureStore.isDriveIdEnabled && (
                    <div className="instructions-item">
                      <div className="instructions-item-header">
                        <span className="title">Photo ID</span>
                        <Button icon={<CameraOutlined />} onClick={onUpdateFromCameraClicked}>
                          Update from Camera
                        </Button>
                      </div>
                      {getIdDocumentSection()}
                    </div>
                  )}
                  {featureStore.isPlateScanEnabled && image && (
                    <div className="instructions-item">
                      <span className="title">PlateScan ({camera})</span>
                      <Image src={image} alt="License Plate" loading="lazy" />
                    </div>
                  )}
                </Col>
              )}
              <Col lg={isInfoSectionEnabled() ? 16 : 24} xs={24}>
                <FormWrapper form={form} onFinish={onFinish} layout="vertical" onValuesChange={handleOnValuesChange}>
                  <Row gutter={{ lg: 32, md: 16, xs: 0 }}>
                    <Col lg={isInfoSectionEnabled() ? 14 : 18} xs={24}>
                      <Row gutter={21}>
                        <Col lg={isInfoSectionEnabled() ? 24 : 12} xs={24}>
                          {isServiceGuest(eiqSecureStore.selectedGuest?.type) && (
                            <Form.Item
                              name="companyName"
                              label="Company name"
                              initialValue={eiqSecureStore.selectedGuest?.companyName}
                              rules={eiqSecureGuestLicenseValidation.carState}>
                              <Input placeholder="Enter company name" />
                            </Form.Item>
                          )}
                          <Form.Item name="firstName" label="First name" initialValue={eiqSecureStore.selectedGuestFirstName}>
                            <Input placeholder="Enter first name" />
                          </Form.Item>
                          <Form.Item name="lastName" label="Last name" initialValue={eiqSecureStore.selectedGuestLastName}>
                            <Input placeholder="Enter last name" />
                          </Form.Item>
                          <Form.Item name="entryType" label="Entry Type" initialValue={defaultEntryType}>
                            <Select
                              options={entryTypeOptions}
                              placeholder="Select Entry Type"
                              popupClassName="so-select"
                              onChange={onEntryTypeChanged}
                            />
                          </Form.Item>

                          {entryTypeValue === EntryType.Passenger && (
                            <>
                              <Form.Item name="driverFirstName" label="Driver first name">
                                <Input placeholder="Enter driver first name" autoFocus />
                              </Form.Item>
                              <Form.Item name="driverLastName" label="Driver last name">
                                <Input placeholder="Enter driver last name" />
                              </Form.Item>
                              <Form.Item
                                name="driverCompanyName"
                                label="Driver company name"
                                rules={eiqSecureGuestLicenseValidation.companyName}>
                                <Input placeholder="Enter driver company name" />
                              </Form.Item>
                            </>
                          )}
                        </Col>

                        <Col lg={isInfoSectionEnabled() ? 24 : 12} xs={24}>
                          {(entryTypeValue === EntryType.Driver || entryTypeValue === EntryType.Passenger) && (
                            <>
                              <Form.Item
                                name="carState"
                                label="License State"
                                initialValue={defaultLicenseState}
                                rules={eiqSecureGuestLicenseValidation.carState}>
                                <SelectState placeholder="Select a license state" popupClassName="so-select" />
                              </Form.Item>
                              <Form.Item
                                name="carLicensePlate"
                                initialValue={defaultLicensePlate}
                                label="License Plate"
                                rules={eiqSecureGuestLicenseValidation.carLicensePlate}>
                                <Input placeholder="Enter license plate" autoFocus />
                              </Form.Item>
                            </>
                          )}
                          <Form.Item name="gate" label="Gate" rules={eiqSecureGuestLicenseValidation.gate} initialValue={defaultGate}>
                            <Select options={gateOptions} placeholder="Select Gate" popupClassName="so-select" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    {isKeyPermissionAllowed && (
                      <Col lg={isInfoSectionEnabled() ? 10 : 6} sm={24}>
                        {isKeyPermissionAllowed && (
                          <Form.Item label="Provide key">
                            <Switch onClick={provideKeyModal.showModal} checked={isSwitchChecked} />
                          </Form.Item>
                        )}
                        {isSwitchChecked && (
                          <>
                            <Form.Item
                              name={['key', 'guestPhoneNumber']}
                              label="Phone number"
                              validateTrigger="onBlur"
                              rules={eiqSecureGuestLicenseValidation.phoneNumber}
                              initialValue={eiqSecureStore.selectedGuest?.phone}>
                              <InputPhone />
                            </Form.Item>
                            <Form.Item name={['key', 'keyNumbers']} label="Select key" rules={eiqSecureGuestLicenseValidation.keyNumber}>
                              <Checkbox.Group options={keyOptions} className="vertical-radio-group"></Checkbox.Group>
                            </Form.Item>
                          </>
                        )}
                      </Col>
                    )}
                  </Row>
                </FormWrapper>
              </Col>
            </Row>
          </div>
        </div>
      </TableContextProvider>
      <KeyActionModal title="Collect" isOpen={collectKeyModal?.isOpenModal} onClose={collectKeyModal?.hideModal} />
      <KeyActionModal title="Provide" isOpen={provideKeyModal?.isOpenModal} onClose={provideKeyModal?.hideModal} />
      <ContactsModal title="Contacts" isOpen={contactsModal?.isOpenModal} onClose={contactsModal?.hideModal} />
      <TurnbackModal title="Turnback" isOpen={turnbackModal?.isOpenModal} onClose={turnbackModal?.hideModal} />
      {isMobileOrTable && <Footer className="guest-license-footer">{admitActions}</Footer>}
    </div>
  );
});
