import { ServerClient } from 'common/utils/http/http-clients';
import { IUploadPhoto, UploadFileTypes } from './file.service.types';

class FileService {
  async uploadPhoto(file: Blob, type: UploadFileTypes = UploadFileTypes.Image, tags?: string): Promise<IUploadPhoto> {
    const data = new FormData();
    data.append('file', file);
    data.append('fileType', type.toString());

    if (tags) {
      data.append('tags', tags);
    }

    const res = await ServerClient.post<IUploadPhoto>('/files/images', data);
    return res?.data;
  }

  async deletePhoto(uri: string): Promise<void> {
    try {
      await ServerClient.delete('/files/images', {
        params: { uri },
      });
    } catch (error) {
      console.error('Delete failed:', error);
      throw error;
    }
  }

  async getFile(url: string, fileType: UploadFileTypes): Promise<Blob> {
    const res = await ServerClient.get<Blob>('/files', {
      params: {
        url: url,
        fileType: fileType,
      },
      responseType: 'blob',
    });
    return res?.data;
  }
}

export const accountService = new FileService();
export default accountService;
