import { ServerClient } from 'common/utils/http/http-clients';
import {
  IAddIdDocument,
  IAddressOccupant,
  IAdmitGuest,
  IAdmitResident,
  IAdmitVisitor,
  ICreateGuest,
  ICreateGuestLimitations,
  IIdDocumentRead,
  ITurnbackGuest,
  ITurnbackVisitor,
  IUpdateGuest,
  IUpdateResidentAddressDataSharing,
} from './guest.service.types';
import { IImportResult } from 'common/models/import-result.interface';
import { notification } from 'common/utils/notification';
import { IServerErrorMessage } from 'common/utils/http/http-client.types';
import configurationStore from 'common/stores/configuration/configuration.store';
import { validityOptions } from 'common/constans/guest-validity-options.constant';
import featureStore from 'common/stores/features/feature.store';
import { GuestValidity } from 'common/enums/guest-validity.enum';

class GuestService {
  async createGuest(residentId: number, addressId: number, data: ICreateGuest): Promise<ICreateGuest> {
    const res = await ServerClient.post<ICreateGuest>(`/residents/${residentId}/addresses/${addressId}/guests`, data);
    return res?.data;
  }

  async updateGuest(residentId: number, addressId: number, data: IUpdateGuest): Promise<IUpdateGuest> {
    const res = await ServerClient.patch<IUpdateGuest>(`/residents/${residentId}/addresses/${addressId}/guests/${data.id}`, data);
    return res?.data;
  }

  async deleteGuest(residentId: number, addressId: number, guestId: number): Promise<any> {
    const res = await ServerClient.delete(`/residents/${residentId}/addresses/${addressId}/guests/${guestId}`);
    return res?.data;
  }

  async updateResidentAddressDataSharing(
    residentId: number,
    addressId: number,
    data: IUpdateResidentAddressDataSharing,
  ): Promise<IAddressOccupant> {
    const res = await ServerClient.patch<IAddressOccupant>(`/residents/${residentId}/addresses/${addressId}/data-sharing`, data);
    return res?.data;
  }

  async admitGuest(residentId: number, addressId: number, guestId: number, data: IAdmitGuest): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/admittance/guests/${guestId}`, data);
    return res?.data;
  }

  async admitVisitor(residentId: number, addressId: number, data: IAdmitVisitor): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/admittance/visitors`, data);
    return res?.data;
  }

  async admitResident(residentId: number, addressId: number, data: IAdmitResident): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/admittance`, data);
    return res?.data;
  }

  async turnbackVisitor(residentId: number, addressId: number, data: ITurnbackVisitor): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/turn-back/visitors`, data);
    return res?.data;
  }

  async turnbackGuest(residentId: number, addressId: number, guestId: number, data: ITurnbackGuest): Promise<void> {
    const res = await ServerClient.post<void>(`/residents/${residentId}/addresses/${addressId}/turn-back/guests/${guestId}`, data);
    return res?.data;
  }

  async admitVisitorToVacantAddress(addressId: number, data: IAdmitGuest): Promise<void> {
    const res = await ServerClient.post<void>(`/addresses/${addressId}/admittance/visitors`, data);
    return res?.data;
  }

  async importGuests(residentId: number, addressId: number, file: any): Promise<IImportResult> {
    const data = new FormData();
    data.append('file', file);
    const res = await ServerClient.post<IImportResult>(`/residents/${residentId}/addresses/${addressId}/guests/import/csv`, data);
    return res?.data;
  }

  async addIdDocument(residentId: number, addressId: number, guestId: number, data: IAddIdDocument): Promise<IIdDocumentRead> {
    const res = await ServerClient.post<IIdDocumentRead>(
      `/residents/${residentId}/addresses/${addressId}/guests/${guestId}/documents/identity`,
      data,
    );
    return res?.data;
  }

  async handleGuestsLimitationsErrors<T>(saveGuest: () => Promise<T>, onError: () => void): Promise<T> {
    try {
      const data = await saveGuest();
      return data;
    } catch (error: any) {
      const errorMassage: IServerErrorMessage = error?.response?.data;
      notification.destroy();

      await configurationStore.requestConfiguration();

      if (errorMassage?.errorCode === 'MaximumGuestValidityRangeExceeded' || errorMassage?.errorCode === 'MaximumDateAheadExceeded') {
        notification.error({
          message: `Community settings have been changed, please try again.`,
        });
        onError();
      }

      if (errorMassage?.errorCode === 'PermanentGuestLimitExceeded') {
        await configurationStore.requestConfiguration();
        notification.error({
          message: `You cannot add more than ${configurationStore.configuration?.guestLimitations.permanentGuestsLimitation} permanent guests to a resident’s guest list. Either remove one permanent guest or add this guest with an expiration date.`,
        });
      }
      return Promise.reject(error);
    }
  }

  getLimitations = (ownershipType: string | null, isAdmin: boolean): ICreateGuestLimitations | null => {
    const isLimitted = guestService.isResidentTypeLimited(ownershipType, isAdmin);
    if (isLimitted) {
      const guestLimitations = configurationStore.configuration?.guestLimitations;
      if (guestLimitations) {
        const limitations: ICreateGuestLimitations = {
          maximumGuestValidityLimitation: guestLimitations.maximumGuestValidityLimitation,
          maximumStartDateAheadLimitation: guestLimitations.maximumStartDateAheadLimitation,
          isPermanentAllowed: guestLimitations.permanentGuestsLimitation > 0,
        };
        return limitations;
      }
    }
    return null;
  };

  isResidentTypeLimited = (ownershipType: string | null, isAdmin: boolean = false): boolean => {
    let isGuestLimitationsEnabled;
    if (isAdmin) {
      isGuestLimitationsEnabled = featureStore.isGuestLimitationsForAdminsEnabled;
    } else {
      isGuestLimitationsEnabled = featureStore.isGuestLimitationsEnabled;
    }
    return (
      isGuestLimitationsEnabled &&
      (configurationStore.configuration?.guestLimitations.ownershipTypesConfiguration?.some(
        (x) => x.type === ownershipType && x.isLimited,
      ) ??
        false)
    );
  };

  getDefaultValidityOptions() {
    return validityOptions;
  }
  getLimitedValidityOptions() {
    return validityOptions.filter((opt) => opt.value !== GuestValidity.Permanent);
  }
}

export const guestService = new GuestService();
export default guestService;
