import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { CapturedId, IdCapture, IdCaptureError, IdSide, RejectionReason } from '@scandit/web-datacapture-id';
import { IIdDocumentModel, IScanditIdCaptureProps } from './scandit-id-capture.types';
import { DateResultJSON } from '@scandit/web-datacapture-id/build/js/SerializedTypes';
import dayjs from 'dayjs';
import { DATE_YYYY_MM_DD } from 'common/constans/date.constants';
import idDocumentRecognitionContext, {
  IdDocumentRecognitionSDKStatus,
} from 'features/eiq-secure/stores/id-document-recognition/id-document-recognition-context';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './scandit-id-capture.scss';

const ScanditIdCapture = observer(({ onRecognized, onFailed }: IScanditIdCaptureProps) => {
  const scannerRef = useRef<any>(null);

  const dateResultJSONToString = (dateResult: DateResultJSON | null) => {
    if (!dateResult?.year || !dateResult.month || !dateResult?.day) {
      return null;
    }
    const date = dayjs(new Date(dateResult.year, dateResult.month, dateResult.day));
    return date.format(DATE_YYYY_MM_DD);
  };

  const mapToIdDocumentModel = (capturedId: CapturedId): IIdDocumentModel => {
    const obj = capturedId.toJSONObject();

    return {
      type: capturedId.document!.documentType,
      imageBase64: capturedId.images.getCroppedDocument(IdSide.Front)!,
      firstName: obj.firstName,
      lastName: obj.lastName,
      secondaryLastName: obj.secondaryLastName,
      gender: obj.sex,
      dateOfBirth: dateResultJSONToString(obj.dateOfBirth),
      nationality: obj.nationality,
      address: obj.address,
      issuingCountry: obj.issuingCountry,
      issuingCountryIso: obj.issuingCountryIso,
      number: obj.documentNumber,
      additionalNumber: obj.documentAdditionalNumber,
      expiredAt: dateResultJSONToString(obj.dateOfExpiry),
      issuedAt: dateResultJSONToString(obj.dateOfIssue),
    };
  };

  const onScan = {
    didCaptureId: (capturedId: CapturedId) => {
      const idDocument = mapToIdDocumentModel(capturedId);
      onRecognized(idDocument);
    },
    didRejectId: (apturedId: CapturedId, reason: RejectionReason) => {
      onFailed('Id document is not recognized, try again');
    },
    didFailWithError: (idCapture: IdCapture, error: IdCaptureError) => {
      onFailed(error.message);
    },
  };

  const cancel = useRef(false);

  const enableScanning = async () => {
    if (cancel.current) return;
    idDocumentRecognitionContext.idCapture?.addListener(onScan);
    if (cancel.current) return;
    await idDocumentRecognitionContext.connectToElement(scannerRef.current);
    if (cancel.current) return;
    await idDocumentRecognitionContext.enableScanning(true);
  };

  useEffect(() => {
    if (idDocumentRecognitionContext.status === IdDocumentRecognitionSDKStatus.Ready) {
      enableScanning();
    }
  }, [idDocumentRecognitionContext.status]);

  useEffect(() => {
    cancel.current = false;
    enableScanning();

    return () => {
      cancel.current = true;
      idDocumentRecognitionContext.enableScanning(false);
      idDocumentRecognitionContext.idCapture?.removeListener(onScan);
    };
  }, []);

  return (
    <div className="scandit-scanner">
      <div ref={scannerRef}></div>
      {idDocumentRecognitionContext.status !== IdDocumentRecognitionSDKStatus.Ready ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 64, color: '#AEB5BE' }} spin />} />
      ) : null}
    </div>
  );
});

export default ScanditIdCapture;
