import accountService from 'common/services/file-service/file.service';
import { UploadFileTypes } from 'common/services/file-service/file.service.types';
import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import { FileImageFilled } from '@ant-design/icons';

interface IEIQImageProps {
  url: string;
  fileType: UploadFileTypes;
  alt?: string;
}

const EIQImage = ({ url, fileType, alt }: IEIQImageProps) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const getFile = async () => {
      const response = await accountService.getFile(url, fileType);
      const imgUrl = URL.createObjectURL(response);
      setImageUrl(imgUrl);
    };

    getFile();
  }, [url]);

  return imageUrl ? <Image src={imageUrl} alt={alt} loading="lazy" /> : <FileImageFilled style={{ fontSize: 64, color: '#BFBFBF' }} />;
};

export default EIQImage;
