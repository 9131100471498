import React, { FC } from 'react';
import { Tabs } from 'antd';
import './eiq-tabs.scss';
import { IEIQTasbsProps } from './eiq-tabs.types';
import { useMedia } from 'common/hooks/use-media/use-media.hook';

const EiqTabs: FC<IEIQTasbsProps> = ({ left, right, className, ...rest }) => {
  const { isMobile } = useMedia();

  const tabBarExtraContent: Record<PositionType, React.ReactNode> = {
    left: left ?? undefined,
    right: right ?? undefined,
  };

  return isMobile ? (
    <>
      {left}
      <Tabs className="eiq-tabs" {...rest} />
    </>
  ) : (
    <Tabs className={`eiq-tabs ${className}`} {...rest} tabBarExtraContent={tabBarExtraContent} />
  );
};

export default EiqTabs;

type PositionType = 'left' | 'right';
