export interface IUploadPhoto {
  absoluteUri: string;
}

export enum UploadFileTypes {
  Image = 1,
  FastAccessPass = 2,
  Citation = 3,
  IncidentReport = 4,
  PlateRecognizer = 5,
  IdDocument = 6,
}
