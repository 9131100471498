import AlertBlock from '../../../../common/components/info-block/alert-block/alert-block';
import React, { useEffect, useMemo, useState } from 'react';
import { useTableContext } from '../../../../common/components/table/table-context';
import { IResidentInfo } from '../../../eiq-manage/pages/eiq-manage-residents/components/info-block/resident-info/resident-info.types';
import { Button, Col, Row, Tag } from 'antd';
import InfoField from '../../../../common/components/info-block/info-field/info-field';
import './eiq-secure-general-view.scss';
import { ContactPhones } from '../../../../common/components/contact-phones/contact-phones';
import { useModal } from '../../../../common/hooks/use-modal/use-modal.hook';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { StrToDate } from '../../../../common/helpers/date.helper';
import EditGeneralViewModal from './edit-general-view-modal/edit-general-view-modal';
import { observer } from 'mobx-react-lite';
import UserAuthStore from '../../../../common/stores/user-auth/user-auth.store';
import { PermissionEnum } from '../../../../common/enums/permission.enum';
import EiqTabsTitleLeft from '../../../../common/components/info-block/title-left/eiq-tabs-title-left';
import dayjs from 'dayjs';
import styles from '../../../../common/components/info-block/contact-block/contact-block.module.scss';
import { getVacationPeriod } from '../../../../common/helpers/vacation.helper';
import { EiqSecureVehiclesInfo } from '../eiq-secure-vehicles-info/eiq-secure-vehicles-info';
import eiqSecureStore from 'features/eiq-secure/stores/eiq-secure.store';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { getPhoneComparer } from 'features/eiq-secure/heplers/phone.helper';

interface IEiqSecureGeneralViewProps {
  isVacantAddress: boolean;
}

export const EiqSecureGeneralView = observer(({ isVacantAddress }: IEiqSecureGeneralViewProps) => {
  const residentContext = useTableContext();
  const selectedRow = residentContext.getSelectedRow();
  const {
    id,
    alertMessage,
    vacationStartDate,
    vacationEndDate,
    phones = [],
    firstName = '',
    lastName = '',
  }: IResidentInfo = isVacantAddress ? { alertMessage: 'Vacant address' } : selectedRow?.resident ?? ({} as IResidentInfo);

  const editResidentModal = useModal({
    onBeforeShow: (setModalInitData) => {
      if (!selectedRow) {
        return;
      }

      setModalInitData({
        extraData: {
          isVacation: vacationStartDate && vacationEndDate,
        },
        initState: {
          id,
          alertMessage,
          vacationPeriod: {
            startDate: StrToDate(vacationStartDate),
            endDate: StrToDate(vacationEndDate),
          },
        },
      });
    },
    onClose: (isSuccessSaved, updatedRow) => {
      if (isSuccessSaved) {
        residentContext.updateSelectedRow({ ...selectedRow, resident: updatedRow });
      }
    },
  });

  const hasVocation = vacationStartDate && vacationEndDate && dayjs().isBetween(vacationStartDate, vacationEndDate, 'day', '[]');

  const isNotOccupant = (ownerShipType?: ResidentTypesEnum) =>
    ownerShipType !== ResidentTypesEnum.NonResident &&
    ownerShipType !== ResidentTypesEnum.Employee &&
    ownerShipType !== ResidentTypesEnum.ClubMember &&
    ownerShipType !== ResidentTypesEnum.Realtor &&
    ownerShipType !== ResidentTypesEnum.Manager;

  const isShowOtherResidentContacts = useMemo(() => {
    const selectedAddressOwnership = eiqSecureStore.getSelectedResidentAddress()?.ownershipType;
    return isNotOccupant(selectedAddressOwnership);
  }, [eiqSecureStore.residentDetails]);

  const getOtherResidentPhones = () => {
    const occupantsPhones = eiqSecureStore.selectedAddressOccupants
      ?.filter((i) => i.residentId !== eiqSecureStore.selectedResident?.id && isNotOccupant(i.ownershipType))
      .flatMap((o) => {
        const p = o.phones.slice().sort(getPhoneComparer());
        return p.map((i) => ({
          label: i.label,
          number: i.number,
          order: i.order,
          residentName: o.name,
        }));
      });

    return occupantsPhones ?? [];
  };

  const vocationBlock = (
    <div className={`vocation-container ${hasVocation ? 'vocation' : 'home'}`}>
      <div>
        <Tag className="eiq-tag" color={hasVocation ? '#D1E3FF' : '#DCDCDC'}>
          {hasVocation ? 'Vacation' : 'Home'}
        </Tag>
      </div>
      <div className="vocation-container__from-to">
        <InfoField
          value={getVacationPeriod(vacationStartDate, vacationEndDate) ?? 'Not available'}
          label="Vacation dates"
          bodyClassName={styles.infoBlockField}
        />
      </div>
    </div>
  );

  const hasUpdateResidentPermission = UserAuthStore.permissions?.includes(PermissionEnum.updateResident) && !isVacantAddress;

  return (
    <>
      <div className="eiq-secure-general">
        <Row justify="space-between">
          <EiqTabsTitleLeft title="" />

          {hasUpdateResidentPermission && (
            <Button icon={<EditIcon />} size="middle" onClick={editResidentModal.showModal}>
              Edit
            </Button>
          )}
        </Row>
        <Row>
          <div className="eiq-secure-general__resident-name">{`${firstName} ${lastName}`}</div>
        </Row>
        <Row className="eiq-secure-general__alert-vocation column-content" gutter={32}>
          <Col xs={24}>{alertMessage && <AlertBlock alertMessage={alertMessage} />}</Col>
          <Col xs={24}>{vocationBlock}</Col>
        </Row>

        {!isVacantAddress && (
          <Row className="contact-cars-row column-content" gutter={32}>
            <Col xs={24}>
              <ContactPhones label="Contacts" phones={phones.sort(getPhoneComparer())} />
            </Col>
            {isShowOtherResidentContacts && (
              <Col xs={24}>
                <ContactPhones label="Other Occupant Contacts" phones={getOtherResidentPhones()} />
              </Col>
            )}
            <Col xs={24}>
              <div>
                <EiqSecureVehiclesInfo />
              </div>
            </Col>
          </Row>
        )}
      </div>

      <EditGeneralViewModal
        {...editResidentModal?.initData}
        title="Edit"
        isEdit={true}
        isOpen={editResidentModal?.isOpenModal}
        onClose={editResidentModal?.hideModal}
      />
    </>
  );
});
